
import { encryptType } from "@/views/Money/Deposit/util/indexType";
import {Dialog} from "vant";
import router from "@/router/index"

export function setEncrypt( props:encryptType = {
    data:"",
    min:0,
    max:20
} ):string {
    let { data,min,max } = props
    if ( data.length ){
        let encrypt = ""
        let len = data.length <= max ? data.length : max
        for ( let i = min; i < len; i++){
            encrypt += "*"
        }
        if ( data.length >= min ){
            try{
                let kStr = data.slice(min,len);
                if ( kStr.length <= 0 )return data;
                let reg = new RegExp(kStr)
                return data.replace(reg,encrypt) as string
            }catch (e) {
                return data
            }
        }else{
            return data
        }
    }else{
        return ""
    }
}


export function filterDefault(list = []) {
    let defaultData = {}
    list.length && list.forEach(item=>{
        if ( item['defaultType'] == 0 ){
            defaultData = item
        }
    })
    if ( JSON.stringify(defaultData) === "{}" ){
        if ( list.length < 1 ){
            Dialog.confirm({
                title:"温馨提示",
                message:"请先添加提现账户"
            }).then(res=>{
                router.push({
                    name:"addAccount",
                    query:{
                        state:"add"
                    }
                })
            }).catch(cat=>cat)
            return
        }else{
            Dialog.confirm({
                title:"温馨提示",
                message:"请先选择提现账户"
            }).then(res=>{
                router.push({
                    name:"accountList",
                    query:{
                        state:"select"
                    }
                })
            }).catch(cat=>cat)
        }
    }
    return defaultData;
}

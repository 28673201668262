import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import AccountListStoreType from "@/views/Money/AccountList/Store/indexType";

@Module({
    name:"AccountList",
    store,
    dynamic: true,
})
export default class AccountList extends VuexModule implements AccountListStoreType{
    AccountListSelect: any = {};
    get getAccountListSelect(){ return this.AccountListSelect }
    @Mutation
    SetAccountListSelect(data: any): void {
        this.AccountListSelect = data
    }

}

export const AccountListStore = getModule( AccountList )
